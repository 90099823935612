(function ($) {
	$.fn.isInView = function () {
		if (this.length === 0) {
			return false;
		}
		var elementTop = this.offset().top;
		var elementBottom = elementTop + this.outerHeight();
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();
		return elementBottom > viewportTop && elementTop < viewportBottom;
	};

	$.fn.isInPercentView = function (percentage) {
		if (this.length === 0) {
			return false;
		}
		var elementTop = this.offset().top;
		var elementBottom = elementTop + this.outerHeight();
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height() * (1 - percentage);

		return elementBottom > viewportTop && elementTop < viewportBottom;
	};

	$.fn.getParallaxScrollPercent = function () {
		if (this.length === 0) {
			return false;
		}
		var elementTop = this.offset().top;
		var elementBottom = elementTop + this.outerHeight();
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();

		const bottomRange = elementTop;
		const topRange = bottomRange + $(window).height() + this.outerHeight();

		return Math.min(1, Math.max(0, (viewportBottom - bottomRange) / (topRange - bottomRange)));
	};
})(jQuery);

function iOS() {
	return (
		["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	);
}

// Elements
let jWindow;
let body;
let header;
let headerTop;
let mastHead;
let parallax;
let about;
let menu;

// Values
let headerHeight;
let headerTopHeight;

// Logic
let mastHeadViewed = false;
let aboutViewed = false;
let menuViewed = false;

$(document).ready(() => {
	jWindow = $(window);
	body = $("body");
	header = $("header");
	headerTop = header.find(".top");
	mastHead = $("mast-head");
	parallax = $("#parallax");
	about = $("#about");
	menu = $("#menu");

	if (iOS()) {
		parallax.css("background-attachment", "scroll");
	}

	// Clone the header so we can get its height.
	const clonedHeader = header.clone();

	// Record the header top height to support smooth shrinking.
	body.append(clonedHeader);
	headerTopHeight = clonedHeader.find(".top").outerHeight();
	clonedHeader.remove();

	// Add the shrink class and get the height to offset the scroll function.
	clonedHeader.addClass("shrink");
	$("body").append(clonedHeader);
	headerHeight = clonedHeader.height();
	clonedHeader.remove();

	$("#mast .hidden").each(function (index) {
		setTimeout(() => {
			$(this).removeClass("hidden");
		}, index * 150);
	});

	// Get the viewport height and multiply it by 1% to get a value for a vh unit.
	let vh = window.innerHeight * 0.01;

	// Set the value in the --vh custom property to the root of the document.
	document.documentElement.style.setProperty("--vh", `${vh}px`);

	window.addEventListener("scroll", onScroll, debounce(onScroll, 50));
	//jWindow.scroll(debounce(onScroll, 50));

	// Trigger the scroll event immediately to account for the page loading with an initial scroll amount.
	onScroll();

	function adjustHeader(scrollTop) {
		if (scrollTop > 50) {
			if (header.hasClass("shrink")) {
				return;
			}
			headerTop.css("height", headerTopHeight);
			header.addClass("shrink");
			setTimeout(() => {
				headerTop.css("height", "");
			});
		} else if (header.hasClass("shrink")) {
			headerTop.css("height", headerTopHeight);
			header.removeClass("shrink");
		}
	}

	function onScroll() {
		// Shrink Header
		const scrollTop = jWindow.scrollTop();

		adjustHeader(scrollTop);

		if (!mastHeadViewed && mastHead.isInView()) {
			mastHeadViewed = true;
			mastHead.find(".hidden").each(function (index) {
				setTimeout(() => {
					$(this).removeClass("hidden");
				}, index * 150);
			});
		}

		if (!aboutViewed && about.isInPercentView(0.35)) {
			aboutViewed = true;
			about.find(".hidden").each(function (index) {
				setTimeout(() => {
					$(this).removeClass("hidden");
				}, index * 150);
			});
		}

		if (!menuViewed && menu.isInPercentView(0.5)) {
			menu.find(".hidden").each(function (index) {
				setTimeout(() => {
					$(this).removeClass("hidden");
				}, index * 150);
			});
		}

		parallax.css("background-position", `50% ${25 + 50 * parallax.getParallaxScrollPercent()}%`);
	}

	// Wire scroll function to fragment links.
	$("a[href^='#'], a[href^='/#']")
		.filter(function () {
			const linkPath = this.href.match(/^[^#]*/)[0];
			const currentPath = window.location.origin + window.location.pathname;

			return linkPath === currentPath;
		})
		.on("click", function (event) {
			if (this.hash !== "") {
				event.preventDefault();

				var hash = this.hash;

				scrollTo($(hash));

				$("#nav-options").css("height", "");
			}
		});

	// Wire mobile navigation menu.
	const mobileNav = $("#mobile-nav");

	const closeNav = (e) => {
		mobileNav.addClass("hidden");
		$("body").off("click").off("touchmove");
	};

	$("#mobile-nav-button").click((e) => {
		// Stop the event propagating to prevent the body event firing and immediately closing the nav.
		e.stopPropagation();

		// Toggle the nav open and close.
		mobileNav.toggleClass("hidden");

		// Add the body event to close the nav on any non-nav click.
		if (!mobileNav.hasClass("hidden")) {
			body.click(closeNav).on("touchmove", closeNav);
		}
	});

	// Prevent nav clicks and touches from propagating.
	mobileNav
		.click((e) => {
			e.stopPropagation();
		})
		.on("touchmove", (e) => {
			e.stopPropagation();
			e.preventDefault();
		});

	// Except ones that are on a nav link.
	mobileNav.find("a").click(closeNav);
});

function scrollTo(section, immediate) {
	if (immediate) {
		$("html, body").scrollTop(section.offset().top) - headerHeight;
	} else {
		$("html, body").animate(
			{
				scrollTop: section.offset().top - headerHeight,
			},
			800
		);
	}
}

function debounce(func, timeout) {
	let inTimeout;
	return (...args) => {
		if (!inTimeout) {
			func.apply(this, args);
		}

		inTimeout = true;

		setTimeout(() => {
			inTimeout = false;
			func.apply(this, args);
		}, timeout);
	};
}
